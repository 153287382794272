import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './app-dashboard.module.css';

function AppDashboard() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Welcome to CMS App Dashboard</h1>

            <div className={styles.gridWrapper}>
                <div className={styles.gridContainer}>
                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/cms-web-app/app-dashboard/app-upload-register-login')}>
                        <span className={styles.labelDashboard}>Upload Register & Login</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/cms-web-app/app-dashboard/app-upload-marquee')}>
                        <span className={styles.labelDashboard}>Upload Marquee Content</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/cms-web-app/app-dashboard/app-baccarat-upload-register-login')}>
                        <span className={styles.labelDashboard}>Baccarat Upload Register & Login</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppDashboard;
