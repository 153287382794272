import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import React, { useState, useEffect } from 'react';

const BASE_URL = 'https://api.xianggangliuhecai.com';
// const BASE_URL = 'http://localhost:8080';

function Header() {
    return (
        <>
            <LogoHeader />
        </>
    );
}

function LogoHeader() {
    const [selectedTab, setSelectedTab] = useState('/tab1');
    const [logoUrl, setLogoUrl] = useState('');

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    useEffect(() => {
        const fetchLogo = async () => {
            try {
                const response = await fetch(`${BASE_URL}/get-logo`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data.length > 0) {
                    setLogoUrl(`${BASE_URL}${data[0].image_url}`);
                }
            } catch (err) {
                console.error('Error fetching logo:', err.message);
            }
        };

        fetchLogo();
    }, []);

    const dropdownOptions = [
        { id: '1', label: '主页', link: '/' },
        { id: '2', label: '开奖记录', link: '/tab2' },
        { id: '3', label: '政策与隐私', link: '/policy' },
    ];

    const [selectedOption, setSelectedOption] = useState('');

    const handleDropdownChange = (option) => {
        setSelectedOption(option);
        handleTabClick(option.link);  // Use the link property for navigation
    };

    return (
        <div className={styles.logo}>
            <Link
                to="/"
                className={selectedTab === '/' ? styles.selectedNavLink : styles.navLink}
                onClick={() => handleTabClick('/')}
            >
                {logoUrl ? <img src={logoUrl} alt="Logo" width="150px" /> : 'Loading...'}
            </Link>

            <CustomDropdown
                dropdownOptions={dropdownOptions}
                selectedOption={selectedOption}
                handleDropdownChange={handleDropdownChange}
            />

        </div>
    );
}

function CustomDropdown({ dropdownOptions, selectedOption, handleDropdownChange }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className={styles.dropdownContainer}>
            {/* Menu Icon */}
            <div className={styles.menuIcon} onClick={toggleMenu}>
                <div className={styles.bar}></div>
                <div className={styles.bar}></div>
                <div className={styles.bar}></div>
            </div>

            {/* Dropdown container that shows options when the menu is open */}
            {isMenuOpen && (
                <div className={styles.dropdownMenu}>
                    {dropdownOptions.map(option => (
                        <Link
                            key={option.id}
                            to={option.link}  // Use `to` prop for navigation
                            className={styles.dropdownItem}
                            onClick={() => {
                                handleDropdownChange(option);
                                setIsMenuOpen(false);  // Close menu after selection
                            }}
                        >
                            {option.label}
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Header;
