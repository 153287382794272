import styles from './Tab2.module.css';
import React, { useState, useEffect } from 'react';

const BASE_URL = 'https://api.xianggangliuhecai.com';
// const BASE_URL = 'http://localhost:8080';

function Tab2() {

    const options = ["澳門六合彩 ", "接口調用 "];
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleOptionSelected = (index) => {
        console.log('Selected index:', index);
        setSelectedIndex(index);
    };

    return (
        <>
            <div style={{ textAlign: 'center', fontSize: '22px', fontWeight: '600', paddingTop: '20px', paddingLeft: '15%', paddingRight: '15%' }}>
                香港六合彩开奖记录
            </div>

            <div>
                <SegmentedControl
                    options={options}
                    selectedIndex={selectedIndex}
                    onOptionSelected={handleOptionSelected}
                />
                {/* Conditionally render content based on selected index */}
                {selectedIndex === 0 && <LotteryResult />}
                {selectedIndex === 1 && <DescriptionAPI />}
            </div>
        </>
    );
}

const SegmentedControl = ({ options, selectedIndex, onOptionSelected }) => {
    const handleOptionClick = (index) => {
        onOptionSelected(index);
    };

    return (
        <div className={styles.segmentedControl}>
            {options.map((option, index) => (
                <button
                    key={index}
                    className={`${styles.segment} ${selectedIndex === index ? styles.selected : ''}`}
                    onClick={() => handleOptionClick(index)}
                >
                    {option}
                </button>
            ))}
        </div>
    );
};

function LotteryResult() {

    const lotteryData = [
        {
            period: '第2024220期',
            openTime: '2024-08-07 21:32:00',
            numbers: [1, 2, 3, 4, 5, 6, 7],
            desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
            color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
            replay: '回放',
        },
        {
            period: '第2024220期',
            openTime: '2024-08-07 21:32:00',
            numbers: [1, 2, 3, 4, 5, 6, 7],
            desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
            color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
            replay: '回放',
        },
        {
            period: '第2024220期',
            openTime: '2024-08-07 21:32:00',
            numbers: [1, 2, 3, 4, 5, 6, 7],
            desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
            color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
            replay: '回放',
        },
        {
            period: '第2024220期',
            openTime: '2024-08-07 21:32:00',
            numbers: [1, 2, 3, 4, 5, 6, 7],
            desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
            color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
            replay: '回放',
        },
        {
            period: '第2024220期',
            openTime: '2024-08-07 21:32:00',
            numbers: [1, 2, 3, 4, 5, 6, 7],
            desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
            color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
            replay: '回放',
        },

    ];

    return (
        <>
            <br />
            <LotteryUI />
            <SegmentedControlAndSearch />
            <LotteryTable lotteryData={lotteryData} />
        </>
    );
}

function DescriptionAPI() {
    return (
        <div className={styles.descAPI}>
            <h2>API 資訊</h2>
            <p>本網站提供查詢接口，供查詢開獎數據。</p>
            <p><strong>地址：</strong> https://api.macaujc.org/api/opencode/:id</p>
            <p><strong>方式：</strong> GET</p>
            <p><strong>返回：</strong> JSON</p>
            <br />
            <h3>調用示例</h3>
            <p>澳門六合彩: https://api.macaujc.org/api/opencode/2032</p>
            <br />
            <h3>返回示例</h3>
            <p>查詢澳門六合彩開獎數據</p>
            <br />
            <h3>返回字段說明</h3>
            <p>1、code: 消息編碼, 0 代表成功；</p>
            <p>2、message: 消息描述；</p>
            <p>3、data：具體開獎數據；issue：期號；openCode：開獎號碼；openTime：開獎時間。</p>
        </div>
    );
}

const LotteryUI = () => {
    const [title, setTitle] = useState('');
    const [balls, setBalls] = useState([]);
    const [numberSpecial, setNumberSpecial] = useState(null);
    const deadline = '2024-12-25T21:15:00'; // You can set this dynamically if needed

    // Function to assign random colors to balls
    const getRandomColor = () => {
        const colors = ['/red.png', '/blue.png', '/green.png'];
        return colors[Math.floor(Math.random() * colors.length)];
    };

    // Fetch lottery results from the API
    useEffect(() => {
        const fetchLotteryResults = async () => {
            try {
                const response = await fetch(`${BASE_URL}/get-lottery-results-1`);
                if (response.ok) {
                    const data = await response.json();
                    // Assuming the API returns numbers as number_1, number_2, etc.
                    const lotteryBalls = [
                        { number: data.number_1, color: getRandomColor() },
                        { number: data.number_2, color: getRandomColor() },
                        { number: data.number_3, color: getRandomColor() },
                        { number: data.number_4, color: getRandomColor() },
                        { number: data.number_5, color: getRandomColor() },
                        { number: data.number_6, color: getRandomColor() },
                    ];
                    setTitle(data.title);
                    setBalls(lotteryBalls);
                    setNumberSpecial({ number: data.number_special, color: getRandomColor() });
                } else {
                    console.error('Failed to fetch lottery results');
                }
            } catch (error) {
                console.error('Error fetching lottery results:', error);
            }
        };

        fetchLotteryResults();
    }, []);

    return (
        <div className={styles.lotteryResultsContainer}>
            <div className={styles.lotteryContent}>
                <div className={styles.lotteryLeft}>
                    <h3>{title}</h3> {/* Fetch title from API */}
                    <div className={styles.lotteryRow}>
                        {balls.map((ball, index) => (
                            <Ball key={index} number={ball.number} image={ball.color} />
                        ))}
                        <span className={styles.plusSign}>+</span>
                        {numberSpecial && <Ball number={numberSpecial.number} image={numberSpecial.color} />}
                    </div>
                </div>
                <div className={styles.lotteryRight}>
                    <Timer deadline={deadline} />
                    <div style={{ marginTop: '20px' }}>
                        <button style={{ padding: '10px 20px', marginRight: '10px', backgroundColor: '#e1a61f', color: '#fff' }}>
                            開獎結果
                        </button>
                        <button style={{ padding: '10px 20px', backgroundColor: 'red', color: '#fff' }}>
                            直播
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const LotteryTable = () => {
    const [lotteryData, setLotteryData] = useState([]);

    useEffect(() => {
        const fetchLotteryData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/get-history-results-1`);
                if (response.ok) {
                    const data = await response.json();

                    // Transform the data to match your hard-coded model
                    const transformedData = data.map(item => {
                        const date = new Date(item.draw_date);

                        // Format date and time to dd-MM-yyyy HH:mm:ss
                        const day = String(date.getDate()).padStart(2, '0');
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const year = date.getFullYear();
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');

                        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

                        return {
                            period: item.title,
                            openTime: formattedDate, // Format as dd-MM-yyyy HH:mm:ss
                            numbers: [
                                item.number_1,
                                item.number_2,
                                item.number_3,
                                item.number_4,
                                item.number_5,
                                item.number_6,
                                item.number_special
                            ],
                            desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'], // Update descriptions as needed
                            color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'], // Update colors as needed
                        };
                    });

                    setLotteryData(transformedData);
                } else {
                    console.error('Failed to fetch lottery data.');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchLotteryData();
    }, []);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const tableHeaders = isMobile
        ? ['期號', '中獎號碼']
        : ['期號', '開獎時間', '中獎號碼', '開獎回放'];

    return (
        <table className={styles.lotteryTable}>
            <thead>
                <tr>
                    {tableHeaders.map((header, index) => (
                        <th key={header} className={`column-${index + 1}`}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {lotteryData.map((row, index) => (
                    <tr key={index}>
                        <td className={styles.lotteryTableColumn1}>{row.period}</td>
                        <td className={styles.lotteryTableColumn2}>{row.openTime}</td>
                        <td className={styles.lotteryTableColumn3}>
                            <div className={styles.lotteryRow}>
                                {row.numbers.slice(0, -1).map((ball, index) => (
                                    <SmallBall key={index} number={ball} image={row.color[index]} description={row.desc[index]} />
                                ))}
                                <span className={styles.smallPlusSign}>+</span>
                                <SmallBall key={row.numbers.length - 1} number={row.numbers[row.numbers.length - 1]} image={row.color[row.numbers.length - 1]} description={row.desc[row.numbers.length - 1]} />
                            </div>
                        </td>
                        <td className={styles.lotteryTableColumn4}>
                            <button style={{ padding: '10px 20px', backgroundColor: 'red', color: '#fff' }}>回放</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const Ball = ({ number, image }) => {
    return (
        <div className={styles.lotteryBall} style={{
            backgroundImage: `url(${image})`,
        }}>
            {number}
        </div>
    );
};

const Timer = ({ deadline }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(deadline) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div className={styles.timerContainer}>
            {timeLeft.hours
                .toString().padStart(2, '0')
                .split('')
                .map((digit, index) => (
                    <span key={index} className={styles.timeDigit}>
                        {digit}
                    </span>
                ))}
            <span>:</span>
            {timeLeft.minutes
                .toString().padStart(2, '0')
                .split('')
                .map((digit, index) => (
                    <span key={index} className={styles.timeDigit}>
                        {digit}
                    </span>
                ))}
            <span>:</span>
            {timeLeft.seconds
                .toString().padStart(2, '0')
                .split('')
                .map((digit, index) => (
                    <span key={index} className={styles.timeDigit}>
                        {digit}
                    </span>
                ))}
        </div>
    );
};

function SegmentedControlAndSearch() {
    const options = ["今年", "去年"];
    const defaultIndex = options.indexOf("今年"); // Default index for 今年

    const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

    const handleOptionSelected = (index) => {
        console.log('Selected index:', index);
        setSelectedIndex(index);
    };

    return (
        <div className={styles.containerSecond}>
            {/* <div className={styles.label}>澳門六合彩開獎視頻</div> */}

            <div>
                <SegmentedControlSecond
                    options={options}
                    selectedIndex={selectedIndex}
                    onOptionSelected={handleOptionSelected}
                />
            </div>

            <div className={styles.searchField}>
                <input type="text" placeholder="輸入期號" />
                <button>🔍</button>
            </div>
        </div>
    );
};

const SegmentedControlSecond = ({ options, selectedIndex, onOptionSelected }) => {
    const handleOptionClick = (index) => {
        onOptionSelected(index);
    };

    return (
        <div className={styles.segmentedControlSecond}>
            {options.map((option, index) => (
                <button
                    key={index}
                    className={`${styles.segmentSecond} ${selectedIndex === index ? styles.selectedSecond : ''}`}
                    onClick={() => handleOptionClick(index)}
                >
                    {option}
                </button>
            ))}
        </div>
    );
};

const SmallBall = ({ number, image, description }) => {
    return (
        <div>
            <div className={styles.lotterySmallBall} style={{
                backgroundImage: `url(${image})`,
            }}>
                {number}
            </div>
            <div style={{ fontSize: '12px', paddingTop: '6px' }}>{description}</div>
        </div>
    );
};

export default Tab2;