import React from 'react';
import StatCard from './StatCard';
import styles from './StatGrid.module.css';

const StatGrid = () => {
    const stats = [
        { desc: 'CURRENT SPEED', number: 60, unit: '秒', title: '平均存款时间', description: 'AVERAGE TIME OF DEPOSIT' },
        { desc: 'TOTALLY AMOUNT', number: 80, unit: '家', title: '合作支付平台', description: 'PAYMENT PLATFORM PARTNERS' },
        { desc: 'CURRENT SPEED', number: 90, unit: '秒', title: '平均取款时间', description: 'AVERAGE TIME OF WITHDRAW' },
        { desc: 'TOTALLY AMOUNT', number: 32, unit: '家', title: '合作游戏平台', description: 'GAMING PROVIDER PARTNERS' },
    ];

    return (
        <>
            <div className={styles.grid}>
                {stats.map((stat, index) => (
                    <StatCard
                        key={index}
                        desc={stat.desc}
                        number={stat.number}
                        unit={stat.unit}
                        title={stat.title}
                        description={stat.description}
                    />
                ))}
            </div>
            <div className={styles.container}>
                <FeatureGrid />
            </div>
        </>
    );
};

function FeatureGrid() {

    const features = [
        {
            icon: "/1.png",
            title: "极速存取转款",
            description: "最新技术自主研发的财务处理系统真正做到极速存、取、转，最大优化网络延迟。"
        },
        {
            icon: "/2.png",
            title: "海量赛事种类",
            description: "每天为您提供近千场精彩体育赛事，让您拥有完美游戏体验。"
        },
        {
            icon: "/3.png",
            title: "加密安全管理",
            description: "独家开发，采用128位加密技术和严格的安全管理体系，让您全情尽享娱乐。"
        },
        {
            icon: "/4.png",
            title: "三端任您选择",
            description: "引领市场的卓越技术，自主研发了全套终端应用，让您随时随地。"
        }
    ];

    return (
        <div className={styles.gridContainer}>
            {features.map((feature, index) => (
                <div className={styles.featureItem} key={index}>
                    <img src={feature.icon} alt={feature.title} className={styles.featureIcon} />
                    <div className={styles.featureContent}>
                        <h3 className={styles.featureTitle}>{feature.title}</h3>
                        <p className={styles.featureDescription}>{feature.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default StatGrid;
