import React from 'react';
import styles from './TitleLine.module.css';

const TitleLine = ({ title, subtitle }) => {
    return (
        <div className={styles.banner}>
            <div className={styles.line}></div>
            <div className={styles.textContainer}>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.subtitle}>{subtitle}</p>
            </div>
            <div className={styles.line}></div>
        </div>
    );
};

export default TitleLine;