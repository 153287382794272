import { useState } from 'react';
import styles from './upload-lottery-results-aomen.module.css';

const BASE_URL = 'https://api.xianggangliuhecai.com';
// const BASE_URL = 'http://localhost:8080';

function UploadLotteryResultsAomen() {
    const [title, setTitle] = useState('');
    const [number1, setNumber1] = useState('');
    const [number2, setNumber2] = useState('');
    const [number3, setNumber3] = useState('');
    const [number4, setNumber4] = useState('');
    const [number5, setNumber5] = useState('');
    const [number6, setNumber6] = useState('');
    const [numberSpecial, setNumberSpecial] = useState('');

    const [notification, setNotification] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setNotification(null);

        // Basic validation
        if (!title || !number1 || !number2 || !number3 || !number4 || !number5 || !number6 || !numberSpecial) {
            setNotification({ type: 'error', message: 'All fields are required.' });
            return;
        }

        const formData = new FormData();
        formData.append('title', title.trim());
        formData.append('number1', number1.trim());
        formData.append('number2', number2.trim());
        formData.append('number3', number3.trim());
        formData.append('number4', number4.trim());
        formData.append('number5', number5.trim());
        formData.append('number6', number6.trim());
        formData.append('numberSpecial', numberSpecial.trim());

        try {
            const response = await fetch(`${BASE_URL}/upload-lottery-results-2`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setNotification({ type: 'success', message: 'Lottery results uploaded successfully!' });
                setTitle('');
                setNumber1('');
                setNumber2('');
                setNumber3('');
                setNumber4('');
                setNumber5('');
                setNumber6('');
                setNumberSpecial('');
            } else {
                const errorData = await response.json();
                setNotification({ type: 'error', message: `Upload failed: ${errorData.error}` });
            }
        } catch (error) {
            setNotification({ type: 'error', message: `Upload failed: ${error.message}` });
        }
    };


    return (
        <div className={styles.container}>
            <h1 style={{ textAlign: 'center' }}>Upload Aomen Lottery Results</h1>

            {notification && (
                <div
                    className={styles.notification}
                    style={{ color: notification.type === 'success' ? 'green' : 'red' }}
                >
                    {notification.message}
                </div>
            )}

            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formGroup}>
                    <label className={styles.label} htmlFor="title">Title:</label>
                    <textarea
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className={styles.inputField}
                        rows="2"
                    />
                </div>

                {/* Flex container for the number fields */}
                <div className={styles.numberFieldsContainer}>
                    <div className={styles.formGroup}>
                        <label className={styles.label} htmlFor="number1">Number 1:</label>
                        <input
                            id="number1"
                            value={number1}
                            onChange={(e) => setNumber1(e.target.value)}
                            className={styles.textareaField}
                            rows="1"
                            type="number"
                            min="0"
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label className={styles.label} htmlFor="number2">Number 2:</label>
                        <input
                            id="number2"
                            value={number2}
                            onChange={(e) => setNumber2(e.target.value)}
                            className={styles.textareaField}
                            rows="1"
                            type="number"
                            min="0"
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label className={styles.label} htmlFor="number3">Number 3:</label>
                        <input
                            id="number3"
                            value={number3}
                            onChange={(e) => setNumber3(e.target.value)}
                            className={styles.textareaField}
                            rows="1"
                            type="number"
                            min="0"
                        />
                    </div>
                </div>

                <div className={styles.numberFieldsContainer}>
                    <div className={styles.formGroup}>
                        <label className={styles.label} htmlFor="number4">Number 4:</label>
                        <input
                            id="number4"
                            value={number4}
                            onChange={(e) => setNumber4(e.target.value)}
                            className={styles.textareaField}
                            rows="1"
                            type="number"
                            min="0"
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label className={styles.label} htmlFor="number5">Number 5:</label>
                        <input
                            id="number5"
                            value={number5}
                            onChange={(e) => setNumber5(e.target.value)}
                            className={styles.textareaField}
                            rows="1"
                            type="number"
                            min="0"
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label className={styles.label} htmlFor="number6">Number 6:</label>
                        <input
                            id="number6"
                            value={number6}
                            onChange={(e) => setNumber6(e.target.value)}
                            className={styles.textareaField}
                            rows="1"
                            type="number"
                            min="0"
                        />
                    </div>
                </div>

                <div className={styles.formGroup}>
                    <label className={styles.label} htmlFor="numberSpecial">Number Special:</label>
                    <input
                        id="numberSpecial"
                        value={numberSpecial}
                        onChange={(e) => setNumberSpecial(e.target.value)}
                        className={styles.textareaField}
                        rows="1"
                        type="number"
                        min="0"
                    />
                </div>

                <button type="submit" className={styles.submitButton}>
                    Submit
                </button>
            </form>
        </div>
    );

}

export default UploadLotteryResultsAomen;
