import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './cms-web-app.module.css';

function CMSWebApp() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Welcome to CMS Web + App</h1>

            <div className={styles.gridWrapper}>
                <div className={styles.gridContainer}>
                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/cms-web-app/dashboard')}>
                        <span className={styles.labelDashboard}>Web</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/cms-web-app/app-dashboard')}>
                        <span className={styles.labelDashboard}>App</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CMSWebApp;
