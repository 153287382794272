import { useState } from 'react';
import styles from './upload-news.module.css';

const BASE_URL = 'https://api.xianggangliuhecai.com';
// const BASE_URL = 'http://localhost:8080';

function UploadNews() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [notification, setNotification] = useState(null);

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setNotification(null);

        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        formData.append('image', image);

        try {
            const response = await fetch(`${BASE_URL}/upload-news`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setNotification({ type: 'success', message: 'Article uploaded successfully!' });
                setTitle('');
                setContent('');
                setImage(null);
            } else {
                const errorData = await response.json();
                setNotification({ type: 'error', message: `Upload failed: ${errorData.error}` });
            }
        } catch (error) {
            setNotification({ type: 'error', message: `Upload failed: ${error.message}` });
        }
    };

    return (
        <div className={styles.container}>
            <h1 style={{ textAlign: 'center' }}>Upload News Article</h1>

            {notification && (
                <div
                    className={styles.notification}
                    style={{ color: notification.type === 'success' ? 'green' : 'red' }}
                >
                    {notification.message}
                </div>
            )}

            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formGroup}>
                    <label className={styles.label} htmlFor="image">Select Image:</label>
                    <input
                        type="file"
                        id="image"
                        accept="image/*"
                        onChange={handleImageChange}
                        className={styles.inputField}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label} htmlFor="title">Title:</label>
                    <textarea
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className={styles.inputField}
                        rows="2"
                    />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label} htmlFor="content">Content:</label>
                    <textarea
                        id="content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className={styles.textareaField}
                        rows="5"
                    />
                </div>
                <button type="submit" className={styles.submitButton}>
                    Submit
                </button>
            </form>
        </div>
    );
}

export default UploadNews;
