import styles from './Footer.module.css';
import React, { useState, useEffect } from 'react';

const BASE_URL = 'https://api.xianggangliuhecai.com';
// const BASE_URL = 'http://localhost:8080';

function Footer() {

    const [footer, setFooter] = useState({ content: '' });

    useEffect(() => {
        const fetchContactInfo = async () => {
            try {
                const response = await fetch(`${BASE_URL}/get-footer`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data.length > 0) {
                    setFooter({ content: data[0].content });
                }
            } catch (err) {
                console.error('Error fetching footer content:', err.message);
            }
        };

        fetchContactInfo();
    }, []);

    return (
        <footer className={styles.footer}>
            <div className={styles.line}></div>
            <p className={styles.label}>{footer.content}</p>
        </footer>
    );
};

export default Footer;
