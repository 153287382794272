import { useState } from 'react';
import styles from './upload-title-descriptions.module.css';

const BASE_URL = 'https://api.xianggangliuhecai.com';
// const BASE_URL = 'http://localhost:8080';

function UploadTitleDescriptions() {
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [notification, setNotification] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');

    // Array for dropdown options
    const dropdownOptions = [
        { id: 1, title: '', description: '' },
        { id: 2, title: '', description: '' },
        { id: 3, title: '', description: '' },
        { id: 4, title: '', description: '' },
    ];

    // Handle dropdown change
    const handleDropdownChange = (e) => {
        const selectedId = parseInt(e.target.value, 10);
        const selected = dropdownOptions.find(option => option.id === selectedId);

        if (selected) {
            setTitle(selected.title);
            setSubTitle(selected.description);
        }
        setSelectedOption(selectedId);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setNotification(null);

        // Validate that an option has been selected
        if (!selectedOption) {
            setNotification({ type: 'error', message: 'Please select a title and description option.' });
            return;
        }

        const data = { title, subtitle: subTitle };

        // Determine the API URL based on the selected option
        const apiUrl = `${BASE_URL}/upload-title-subtitle-${selectedOption}`;

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setNotification({ type: 'success', message: 'Title and description uploaded successfully!' });
                setTitle('');
                setSubTitle('');
                setSelectedOption(''); // Reset selected option
            } else {
                // Try to parse the error response as JSON
                try {
                    const errorData = await response.json();
                    setNotification({ type: 'error', message: `Upload failed: ${errorData.error || 'Unknown error'}` });
                } catch (jsonError) {
                    // If parsing fails, fallback to plain text error message
                    const errorText = await response.text();
                    setNotification({ type: 'error', message: `Upload failed: ${errorText || 'Unknown error'}` });
                }
            }
        } catch (error) {
            setNotification({ type: 'error', message: `Upload failed: ${error.message}` });
        }
    };


    return (
        <div className={styles.container}>
            <h1 style={{ textAlign: 'center' }}>Upload Title & Description</h1>

            {notification && (
                <div
                    className={styles.notification}
                    style={{ color: notification.type === 'success' ? 'green' : 'red' }}
                >
                    {notification.message}
                </div>
            )}

            <form onSubmit={handleSubmit} className={styles.form}>
                {/* Dropdown List */}
                <div className={styles.formGroup}>
                    <select
                        id="dropdown"
                        value={selectedOption}
                        onChange={handleDropdownChange}
                        className={styles.selectField}
                        style={{
                            width: '100%',
                            height: '40px',
                            fontSize: '16px',
                            borderRadius: '5px', // Add corner radius
                            border: '1px solid #ccc', // Optional: border to make the radius visible
                            padding: '0 10px', // Optional: padding for space inside
                            boxSizing: 'border-box', // Ensures padding and border are included in width/height
                            appearance: 'none', // Remove default dropdown arrow
                            backgroundColor: '#fff', // Ensure background color matches
                        }}
                    >
                        <option value="">Select..</option>
                        {dropdownOptions.map(option => (
                            <option key={option.id} value={option.id}>
                                Title & Description {option.id}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Title Field */}
                <div className={styles.formGroup}>
                    <label className={styles.label} htmlFor="title">Title:</label>
                    <textarea
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className={styles.inputField}
                        rows="2"
                    />
                </div>

                {/* Description Field */}
                <div className={styles.formGroup}>
                    <label className={styles.label} htmlFor="description">Description:</label>
                    <textarea
                        id="description"
                        value={subTitle}
                        onChange={(e) => setSubTitle(e.target.value)}
                        className={styles.textareaField}
                        rows="2"
                    />
                </div>

                <button type="submit" className={styles.submitButton}>
                    Submit
                </button>
            </form>
        </div>
    );
}

export default UploadTitleDescriptions;
