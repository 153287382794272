import { useState } from 'react';
import styles from './upload-footer.module.css';

const BASE_URL = 'https://api.xianggangliuhecai.com';
// const BASE_URL = 'http://localhost:8080';

function UploadFooter() {
    const [content, setContent] = useState('');

    const [notification, setNotification] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setNotification(null);

        // Basic validation
        if (!content) {
            setNotification({ type: 'error', message: 'All fields are required.' });
            return;
        }

        const formData = new FormData();
        formData.append('content', content.trim());

        try {
            const response = await fetch(`${BASE_URL}/upload-footer`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setNotification({ type: 'success', message: 'Footer content uploaded successfully!' });
                setContent('');
            } else {
                const errorData = await response.json();
                setNotification({ type: 'error', message: `Upload failed: ${errorData.error}` });
            }
        } catch (error) {
            setNotification({ type: 'error', message: `Upload failed: ${error.message}` });
        }
    };


    return (
        <div className={styles.container}>
            <h1 style={{ textAlign: 'center' }}>Upload Footer Content</h1>

            {notification && (
                <div
                    className={styles.notification}
                    style={{ color: notification.type === 'success' ? 'green' : 'red' }}
                >
                    {notification.message}
                </div>
            )}

            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formGroup}>
                    <label className={styles.label} htmlFor="content">Content:</label>
                    <textarea
                        id="content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className={styles.inputField}
                        rows="2"
                    />
                </div>

                <button type="submit" className={styles.submitButton}>
                    Submit
                </button>
            </form>
        </div>
    );

}

export default UploadFooter;
