import styles from './policy.module.css';

function Policy() {
    return (
        <>
            <div style={{ textAlign: 'center', fontSize: '22px', fontWeight: '600', paddingTop: '20px', paddingLeft: '15%', paddingRight: '15%' }}>
                政策与隐私
            </div>

            <div>
                <PolicyPrivacy />
            </div>
        </>
    );
}


function PolicyPrivacy() {
    return (
        <div className={styles.policyPrivacy}>
            <br />
            <br />
            <p style={{ fontSize: '18px' }}>您的隐私对我们非常重要。六合彩开奖应用程序不会收集、存储或共享任何用户的个人信息。我们致力于确保您的数据完全保密。本应用程序仅提供彩票开奖结果和直播视频，不需要任何个人数据或权限。</p>
            <br />
            <p style={{ fontSize: '18px' }}>使用此应用程序，您可以放心，您的隐私将得到充分保护。没有追踪，没有数据收集——只有快速、可靠的开奖结果。</p>
        </div>
    );
}

export default Policy;