import React from 'react';
import styles from './StatCard.module.css';

const StatCard = ({ desc, number, unit, title, description, backgroundImage }) => {
    return (
        <div
            className={styles.card}
        >
            <div className={styles.speedometer}>
                <div className={styles.arc}></div>
                <div className={styles.numberContainer}>
                    <span className={styles.desc}>{desc}</span>
                    <span className={styles.number}>{number}</span>
                    <span className={styles.unit}>{unit}</span>
                </div>
            </div>
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.description}>{description}</p>
        </div>
    );
};

export default StatCard;


