import React from 'react';
import styles from './NotFound.module.css';

function NotFound() {
    return (
        <div className={styles.notFoundContainer}>
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you're looking for doesn't exist.</p>
        </div>
    );
}

export default NotFound;
